import { ref } from "@vue/composition-api";

export function useFindUsers() {
  const usersParams = ref({
    chars: "",
    limit: 15,
  });

  const findUser = _.debounce((text: string) => {
    usersParams.value.chars = text;
  }, 400);

  return {
    usersParams,
    findUser,
  };
}
