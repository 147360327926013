










































































































import { computed, defineComponent, PropType, ref, watch } from "@vue/composition-api";
import { VueEditor } from "vue2-editor";

import DateRangePicker from "@/app/components/Base/DateRangePicker.vue";
import Dropzone from "@/app/components/Base/Dropzone.vue";
import VSelect from "@/app/components/Base/VSelect.vue";
import { TASK_DATA } from "@/shared/constants/components/Task/Task";
import { useRoute } from "@/shared/hooks";
import { useProject, useProjectTasks, useTaskPriorities, useTasks, useUsers } from "@/shared/hooks/fetchers";
import { useFindTasks } from "@/shared/hooks/tasks/useFindTasks";
import { useFindUsers } from "@/shared/hooks/users/useFindUsers";
import { ComboItem } from "@/shared/types/components/Base/VSelectItem";
import { TaskFormTasks, Users } from "@/shared/types/components/Task/TaskForm";
import { TaskInput } from "@/shared/types/schema.types";

type ComboKey = Extract<keyof TaskInput, "priorityCode" | "authorId" | "executorId" | "parentTaskId" | "coexecutorId">;


export default defineComponent({
  name: "TaskForm",
  components: {
    VueEditor,
    VSelect,
    DateRangePicker,
    Dropzone,
  },
  props: {
    value: {
      type: Object as PropType<TaskInput>,
      default: () => null,
    },
    initialUsers: {
      type: Array as PropType<Users>,
      default: () => [],
    },
    initialTasks: {
      type: Array as PropType<TaskFormTasks>,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const route = useRoute();
    const projectId = route.params.id;
    const taskId = route.params.taskId;
    const { findUser, usersParams } = useFindUsers();
    const { taskParams, findTask } = useFindTasks();
    const { data: users } = useUsers(usersParams.value);
    const { data: taskPriorities } = useTaskPriorities();
    const { data: tasks } = projectId? useProjectTasks({
      id: projectId,
      taskFilter: taskParams.value.taskFilter, 
    }) : useTasks(taskParams.value);
    const { data: project } = projectId? useProject({ id: projectId }) : { data: null };

    const taskData = ref<TaskInput>(
      _.merge(
        _.cloneDeep(TASK_DATA),
        props.value,
      ),
    );
    const usersOptions = computed<Users>(() => {
      return _.uniqBy([...props.initialUsers, ...users.value || []], 'id');
    });

    const tasksOptions = computed<TaskFormTasks>(() => _([...props.initialTasks || [], ...tasks.value || []]).uniqBy('id').filter(task => task.id !== taskId).value());

    const changeSelect = (k: ComboKey, ev: ComboItem | null) => {
      if (!taskData?.value || !k) return;
      taskData.value = {
        ...taskData.value,
        [k]: (ev && ev.toString()) || null,
      };
      onValueChange();
    };

    function onDataPickerChange(date: string) {
      taskData.value.deadline = date;
      emit('input', taskData.value);
    }

    function onValueChange() {
      emit('input', taskData.value);
    }


    watch(
      () => props.value as TaskInput,
      (val) => {
        if (!val) return;
        taskData.value =
          _.merge(
            _.cloneDeep(TASK_DATA),
            val,
          );
      },
      { deep: true },
    );

    return {
      taskData,
      users,
      findUser,
      findTask,
      changeSelect,
      taskPriorities,
      tasks,
      onValueChange,
      onDataPickerChange,
      projectId,
      project,
      usersOptions,
      tasksOptions,
      taskParams,
    };
  },
});
