import { TaskInputExt } from "@/shared/types/components/Task/Task";
import { TaskPriorityCode } from "@/shared/types/schema.types";

export const TASK_DATA: TaskInputExt = {
  name: "",
  parentTaskId: null,
  priorityCode: TaskPriorityCode.Normal,
  executorId: "",
  coexecutorId: "",
  deadline: 0,
  description: "<div></div>",
  attachmentInputs: [],
};
