import { ref } from "@vue/composition-api";

export function useFindTasks() {
  const taskParams = ref({ taskFilter: { chars: "" } });

  const findTask = _.debounce((text: string) => {
    taskParams.value.taskFilter.chars = text;
  }, 400);

  return {
    taskParams,
    findTask,
  };
}
