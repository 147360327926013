var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex justify-between mb-10"},[_c('section',{staticClass:"flex-grow mr-10 flex-shrink w-2/3"},[_c('r-input',{staticClass:"mb-10",attrs:{"label":"Название"},on:{"input":_vm.onValueChange},model:{value:(_vm.taskData.name),callback:function ($$v) {_vm.$set(_vm.taskData, "name", $$v)},expression:"taskData.name"}}),_c('VueEditor',{on:{"input":_vm.onValueChange},model:{value:(_vm.taskData.description),callback:function ($$v) {_vm.$set(_vm.taskData, "description", $$v)},expression:"taskData.description"}}),_c('dropzone',{ref:"myVueDropzone",attrs:{"id":"dropzone"},on:{"input":function($event){return _vm.$emit('input', _vm.taskData);}},model:{value:(_vm.taskData.attachmentInputs),callback:function ($$v) {_vm.$set(_vm.taskData, "attachmentInputs", $$v)},expression:"taskData.attachmentInputs"}})],1),_c('section',{staticClass:"flex-shrink w-1/3"},[_c('date-range-picker',{staticClass:"mb-6 w-full",attrs:{"placeholder":"Срок исполнения","value":_vm.taskData.deadline || ''},on:{"input":_vm.onDataPickerChange}}),_c('v-select',{staticClass:"mb-10",attrs:{"value":_vm.taskData.priorityCode,"items":_vm.taskPriorities,"text-value":"name","id-value":"code","placeholder":"Приоритет"},on:{"input":function($event){return _vm.changeSelect('priorityCode', $event)}}}),_c('v-select',{staticClass:"mb-6",attrs:{"is-searchable":"","value":_vm.taskData.executorId,"items":_vm._.filter(_vm.usersOptions, function (user) { return user.id !== _vm.taskData.coexecutorId; }),"text-value":"fullName","placeholder":"Исполнитель"},on:{"input":function($event){return _vm.changeSelect('executorId', $event)},"search-change":_vm.findUser},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var search = ref.search;
return [_c('span',{staticClass:"complex-select-option__title block"},[_c('text-highlight',{attrs:{"queries":search}},[_vm._v(_vm._s(item.fullName))])],1),_c('small',{staticClass:"complex-select-option__small"},[_vm._v(_vm._s(item.position))])]}}])}),_c('v-select',{staticClass:"mb-10",attrs:{"is-searchable":"","value":_vm.taskData.coexecutorId,"items":_vm._.filter(_vm.usersOptions, function (user) { return user.id !== _vm.taskData.executorId; }),"text-value":"fullName","placeholder":"Соисполнитель"},on:{"input":function($event){return _vm.changeSelect('coexecutorId', $event)},"search-change":_vm.findUser},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var search = ref.search;
return [_c('span',{staticClass:"complex-select-option__title block"},[_c('text-highlight',{attrs:{"queries":search}},[_vm._v(_vm._s(item.fullName))])],1),_c('small',{staticClass:"complex-select-option__small"},[_vm._v(_vm._s(item.position))])]}}])}),(_vm.project && _vm.projectId)?_c('div',{staticClass:"project-info__wrapper mb-6"},[_c('span',{staticClass:"project-info__label"},[_vm._v("Проект")]),_c('rir-tooltip',{attrs:{"activator-hover":""}},[_c('span',{staticClass:"project-info__name",attrs:{"slot":"activator"},slot:"activator"},[_vm._v(" "+_vm._s(_vm.project.name)+" ")]),_c('span',[_vm._v(_vm._s(_vm.project.name))])])],1):_vm._e(),_c('v-select',{staticClass:"mb-10",attrs:{"is-searchable":"","value":_vm.taskData.parentTaskId,"items":_vm.tasksOptions,"text-value":"name","placeholder":"Родительская задача"},on:{"input":function($event){return _vm.changeSelect('parentTaskId', $event)},"search-change":_vm.findTask},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var search = ref.search;
return [_c('span',{staticClass:"complex-select-option__title block"},[_c('text-highlight',{attrs:{"queries":search}},[_vm._v(_vm._s(item.name))])],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }